import logo from './logo.svg';
import React from 'react';
import './App.css';


const App = () => {
  const [changingText,setChangingText] = React.useState('ecisions')
  React.useEffect(()=>{
  setTimeout(() => {
    const newH1Text = ['ata', 'ocuments', 'ecisions'][Math.floor(Math.random() * 3)];
    setChangingText(newH1Text);
  }, 10000); 
},[changingText])

  return (
    <div className="bg-black min-h-screen flex items-center justify-center">
      <div className="bg-black p-8 rounded shadow-md w-full md:w-2/3 lg:w-1/2 xl:w-1/3">
        <div className="flex items-center justify-center ">
      <img src={logo} className="App-logo" alt="logo" /></div>
      <span style={{display: 'flex'}}>
      <h1 className="text-yellow-300 text-3xl font-semibold mb-4">AI </h1>
      <h1 className="text-purple-300 text-3xl font-semibold mb-4">PowerD</h1>
      <h1 className="text-yellow-100 text-3xl font-semibold mb-4" >{changingText}</h1>
      </span>
        <p className="text-gray-200 mb-8">
          Specializing in LLM fine-tuning and business process design around AI and Machine Learning.
        </p>

        <div className="mb-4">
        <h1 className="text-yellow-300 text-3xl font-semibold mb-4">Contact:</h1>
          <label className="block text-gray-600 text-sm font-semibold mb-2">Email Address</label>
          <input
            type="email"
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="example@example.com"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-600 text-sm font-semibold mb-2">Message</label>
          <textarea
            className="w-full p-2 border border-gray-300 rounded"
            rows="4"
            placeholder="Your message..."
          ></textarea>
        </div>

        <button className="bg-yellow-600 text-white p-2 rounded hover:bg-purple-600">
          Contact Us
        </button>
      </div>
    </div>
  );
};

export default App;
